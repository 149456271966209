<template>
  <b-dropdown
    v-if="userData.roles.length>1"
    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
    :text="userData.role"
    variant="flat-primary"
  >
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <b-dropdown-item
      v-for="(item, index) in userData.roles"
      v-if="item.name!=userData.role"
      :id="`role-${index}`"
      :key="index"
      @click="changeRole(item)"
    >
      {{ item.name }}
    </b-dropdown-item>

  </b-dropdown>

</template>

<script>
import {
  BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BDropdownItem,
    BDropdown,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  created() {

  },
  methods: {
    changeRole(item) {
      if (item.name === this.userData.role) { return }
      store.dispatch('app/changeRole', { role_id: item.id })
        .then(response => {
          const userData = response.data.data
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          this.userData = userData
          this.$bvToast.toast(`${userData.role} ${this.$t('Rolüne Geçiş Yapıldı')}`, {
            title: this.$t('İşlem Başarılı'),
            variant: 'success',
            solid: false,
          })
        })
    },
  },
}
</script>
