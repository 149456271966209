<template>
  <b-nav-item-dropdown
    v-if="notificationData"
    ref="notificationDropDown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-if="notificationData.notification_count>0"
        :badge="notificationData.notification_count"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
      <feather-icon
        v-else
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Bildirimler') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          ...
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >

      <!-- System Notification Toggler -->
      <div
        v-if="notificationData.notifications.data.length<=0"
        class="media d-flex align-items-center"
      >
        <h6

          class="font-weight-bolder mr-auto mb-0"
        >
          Bildirim Mevcut Değil
        </h6>

      </div>

      <!-- System Notifications -->
      <b-link
        v-for="notification in notificationData.notifications.data"
        :key="notification.id"

        @click="linkTo(notification)"
      >
        <b-media :style="notification.is_read==false?'background:#ffeaea;':''">
          <template #aside>
            <b-avatar
              size="32"
              :variant="$staticParams.getNotificationType(notification.warning_type).color"
            >
              <feather-icon :icon="$staticParams.getNotificationType(notification.warning_type).icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="float-right mb-25 chat-time">{{ dateFormat(notification.created_at) }}</small>
          <small class="notification-text">{{ notification.message }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      :to="{ name: 'notifications' }"
    >Tüm Bildirimleri Gör</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notificationData: null,
    }
  },
  created() {
    // this.fetchNotifications()
    // setInterval(() => { this.fetchNotifications() }, 60000)
  },
  methods: {
    linkTo(item) {
      this.$refs.notificationDropDown.hide(true)
      this.$router.push(this.$staticParams.buildNotificationLink(item))
    },
    dateFormat(date) { return this.$moment(date).fromNow() },
    fetchNotifications() {
      // eslint-disable-next-line no-unreachable
      store.dispatch('app/fetchNotifications', { })
        .then(response => {
          this.notificationData = response.data.data
        })
    },
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: 'Congratulation Sam 🎉',
        avatar: require('@/assets/images/avatars/6-small.png'),
        subtitle: 'Won the monthly best seller badge',
        type: 'light-success',
      },
      {
        title: 'New message received',
        avatar: require('@/assets/images/avatars/9-small.png'),
        subtitle: 'You have 10 unread messages',
        type: 'light-info',
      },
      {
        title: 'Revised Order 👋',
        avatar: 'MD',
        subtitle: 'MD Inc. order updated',
        type: 'light-danger',
      },
    ]
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
